var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col scrollToElement relative",class:['form-field', {
        'form-field--invalid': _vm.isError,
        'bg-gray-100 p-5 rounded-xl': _vm.parentData.form_entity_type != 'BLOCK',
        'consent-form-field-responsive': _vm.previewForm
    }]},[(_vm.fieldData.cascading_fields.length>0)?_c('div',{staticClass:"justify-between"},_vm._l((_vm.fieldData.cascading_fields),function(field,index){return _c('div',{key:index,staticClass:"w-full mr-1"},[(_vm.selectedFieldValue == _vm.fieldData.option_value)?_c('div',[(_vm.selectedFieldValue == _vm.fieldData.option_value && (_vm.fieldData.cascading_fields).length)?_c('label',{staticClass:"mt-2 form-label text-md font-medium inline-block text-base-content",class:{responsiveLabel: _vm.previewForm},attrs:{"for":"#"}},[_c('span',{class:{"pfield":_vm.fieldData.type==="CANDIDATE_SIGNATUTRE"}},[_vm._v(_vm._s(field.label))]),(field.mandatory ||(field.field_validation_regex && field.error))?_c('span',{staticClass:"ml-1 inline text-red-500 no-print"},[_vm._v(" * ")]):_vm._e()]):_vm._e(),(_vm.selectedFieldValue == _vm.fieldData.option_value)?_c('fieldset',{staticClass:"fieldset",class:{'formFieldFieldSet': _vm.previewForm},attrs:{"disabled":!!_vm.isDisabled ||  (!field.editable ) || _vm.disabled || _vm.isAddReadOnly}},[_c(_vm.getCascadedFieldType(field)?.tag,_vm._b({key:field.field_id,ref:"field",refInFor:true,tag:"component",staticClass:"relative",class:{dynamicInputFields: _vm.previewForm, uppercase: _vm.uppercase},attrs:{"path":_vm.getPathString([
                            ..._vm.path,
                            { field: 'field_fe_id', value: field.field_fe_id || field.field_id },
                        ]),"fieldData":field,"selectedFieldValue":_vm.fieldValue,"userData":_vm.userData,"parentData":_vm.parentData,"error":field.error,"previewMode":_vm.previewMode,"previewForm":_vm.previewForm,"disabled":!!_vm.isDisabled ||  _vm.disabled || _vm.isAddReadOnly ||  (!field.editable ),"hasSignature":_vm.hasSignature,"notInsideBlock":_vm.notInsideBlock,"formAlreadySubmitted":_vm.formAlreadySubmitted,"isCopyBlock":_vm.isCopyBlock,"checkBoxId":_vm.checkBoxId,"isBlockDeleted":_vm.isBlockDeleted,"hidden":_vm.hidden},on:{"nextSection":_vm.nextSection,"addTimeline":_vm.addTimeline,"removeTimeline":_vm.removeTimeline,"handleFieldValueChnage":_vm.handleFieldValueChnage,"checkCheckboxId":_vm.checkCheckboxId,"checkBoxData":_vm.checkBoxData},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},'component',_vm.getCascadedFieldTypeProp(field),false))],1):_vm._e(),(field.options?.length>0 && field.value)?_c('div',_vm._l((field.options),function(field1,index){return _c('div',{key:index},[(field1?.cascading_fields.length > 0)?_c('div',{staticClass:"w-full mr-1"},[_c('cascading-field',{staticClass:"my-4",class:{dynamicFields: _vm.previewForm, uppercase: _vm.uppercase},attrs:{"selectedFieldValue":field.value,"fieldData":field1,"parentData":_vm.parentData,"userData":_vm.userData,"path":[
                            ..._vm.path,
                            { field: 'field_fe_id', value: field.field_fe_id || field.field_id },
                        ],"previewMode":_vm.previewMode,"validations":field1.field_validation_regex,"previewForm":_vm.previewForm,"notInsideBlock":true,"formAlreadySubmitted":_vm.formAlreadySubmitted,"isAddReadOnly":_vm.isAddReadOnly,"hidden":_vm.hidden}})],1):_vm._e()])}),0):_vm._e()]):_vm._e()])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }